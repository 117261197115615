var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      !this.error
        ? _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-4", attrs: { title: "User Details" } },
                    [
                      _c(
                        "b-form",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Name" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: !_vm.selected_user,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.user.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "name", $$v)
                                  },
                                  expression: "user.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Company" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: !_vm.selected_user,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.user.company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "company", $$v)
                                  },
                                  expression: "user.company",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Email" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: !_vm.selected_user,
                                  type: "email",
                                },
                                model: {
                                  value: _vm.user.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "email", $$v)
                                  },
                                  expression: "user.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-4",
                              attrs: { switch: "" },
                              model: {
                                value: _vm.user.emailVerified,
                                callback: function ($$v) {
                                  _vm.$set(_vm.user, "emailVerified", $$v)
                                },
                                expression: "user.emailVerified",
                              },
                            },
                            [_vm._v(" Email Verified ")]
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Password" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "password",
                                      placeholder: "Set using button to right",
                                      disabled: !_vm.selected_user,
                                      state: _vm.user.password_valid,
                                    },
                                    model: {
                                      value: _vm.user.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "password", $$v)
                                      },
                                      expression: "user.password",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            disabled:
                                              !_vm.selected_user ||
                                              _vm.user.password.length === 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetUserPassword()
                                            },
                                          },
                                        },
                                        [_vm._v("Set")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { state: _vm.user.password_valid } },
                                [
                                  _vm._v(
                                    " Password must be at least 8 characters long and contain both upper and lowercase characters, and a number. "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("b-form-radio-group", {
                            staticClass: "mb-1",
                            attrs: {
                              options: _vm.user_types,
                              disabled: !_vm.selected_user,
                            },
                            model: {
                              value: _vm.user.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "type", $$v)
                              },
                              expression: "user.type",
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              staticClass: "mt-2",
                              on: { click: _vm.updateUserInfo },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-4",
                      attrs: { title: "Accessible Loggers" },
                    },
                    [
                      _c("b-table", {
                        attrs: {
                          items: _vm.allUserLoggers,
                          fields: _vm.loggerTableFields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(id)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "float-right",
                                      attrs: {
                                        variant: "secondary",
                                        to: "/oem/logger/" + data.item.id,
                                      },
                                    },
                                    [
                                      _vm._v(" Go to logger admin page "),
                                      _c("fa-icon", {
                                        attrs: { icon: "arrow-right" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          346234441
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-4",
                      attrs: { title: "User Subscription" },
                    },
                    [
                      _c(
                        "b-form",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: { switch: "" },
                                      model: {
                                        value: _vm.userSub.active,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userSub, "active", $$v)
                                        },
                                        expression: "userSub.active",
                                      },
                                    },
                                    [_vm._v(" Apply a User Subscription ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "oem-user-edit-usub-numLoggers",
                                    },
                                  },
                                  [_vm._v(" Number of Loggers ")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "oem-user-edit-usub-numLoggers",
                                          type: "number",
                                          min: "1",
                                          disabled:
                                            !_vm.userSub.active ||
                                            _vm.userSub.numLoggers === null,
                                        },
                                        model: {
                                          value: _vm.userSub.numLoggers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userSub,
                                              "numLoggers",
                                              $$v
                                            )
                                          },
                                          expression: "userSub.numLoggers",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: {
                                                checked:
                                                  _vm.userSub.active &&
                                                  _vm.userSub.numLoggers ===
                                                    null,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.numLoggersChecked()
                                                },
                                              },
                                            },
                                            [_vm._v(" Unlimited ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "label",
                                  {
                                    attrs: { for: "oem-user-edit-usub-quota" },
                                  },
                                  [_vm._v(" Quota Per Logger ")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "oem-user-edit-usub-quota",
                                          type: "number",
                                          min: "1",
                                          disabled: _vm.uSubQuotaDisabled,
                                        },
                                        model: {
                                          value: _vm.userSub.quotaPerLogger,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userSub,
                                              "quotaPerLogger",
                                              $$v
                                            )
                                          },
                                          expression: "userSub.quotaPerLogger",
                                        },
                                      }),
                                      _c("b-form-select", {
                                        staticStyle: { "max-width": "6em" },
                                        attrs: {
                                          options: _vm.quotaMultipliers,
                                          disabled: _vm.uSubQuotaDisabled,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.userSub.quotaMultiplier,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userSub,
                                              "quotaMultiplier",
                                              $$v
                                            )
                                          },
                                          expression: "userSub.quotaMultiplier",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: {
                                                checked: _vm.uSubQuotaInfinite,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.quotaPerLoggerChecked()
                                                },
                                              },
                                            },
                                            [_vm._v(" Unlimited ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "label",
                                  {
                                    attrs: { for: "oem-user-edit-usub-start" },
                                  },
                                  [_vm._v(" Start Date ")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "oem-user-edit-usub-start",
                                          type: "date",
                                          disabled: !_vm.userSub.active,
                                        },
                                        model: {
                                          value: _vm.userSub.start,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userSub, "start", $$v)
                                          },
                                          expression: "userSub.start",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                variant: "accent",
                                                disabled: !_vm.userSub.active,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.userSub.start = _vm.today
                                                },
                                              },
                                            },
                                            [_vm._v(" Today ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "label",
                                  { attrs: { for: "oem-user-edit-usub-end" } },
                                  [_vm._v(" End Date ")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "oem-user-edit-usub-end",
                                          type: "date",
                                          min: _vm.userSub.start,
                                          disabled:
                                            !_vm.userSub.active ||
                                            _vm.userSub.end === null,
                                        },
                                        model: {
                                          value: _vm.userSub.end,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userSub, "end", $$v)
                                          },
                                          expression: "userSub.end",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: {
                                                checked:
                                                  _vm.userSub.active &&
                                                  _vm.userSub.end === null,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.userSubEndDateChecked()
                                                },
                                              },
                                            },
                                            [_vm._v(" Infinite ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-btn",
                            {
                              staticClass: "mt-2",
                              on: {
                                click: function ($event) {
                                  return _vm.updateUserSubscription()
                                },
                              },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "h4",
                        [
                          _vm._v(" Logger Subscriptions "),
                          _c(
                            "b-btn",
                            {
                              staticClass: "mb-2 ml-2 float-right",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.addLoggerSub()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "plus" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-table", {
                        attrs: {
                          items: _vm.loggerSubs,
                          fields: _vm.loggerSubFields,
                          "sort-icon-left": "",
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "empty",
                              fn: function () {
                                return [
                                  _c("p", { staticClass: "lead text-center" }, [
                                    _vm._v("No Logger Subscriptions Found"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(id)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mr-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editLoggerSub(data.value)
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "edit" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loggerSubDel(data.value)
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "trash" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          301598780
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        header: "Danger Area",
                        "border-variant": "danger",
                        "header-border-variant": "danger",
                        "header-text-variant": "danger",
                        align: "center",
                      },
                    },
                    [
                      _c("b-row", { attrs: { "align-h": "center" } }, [
                        _c("p", [
                          _vm._v(
                            " Delete this user and all their account data from the system."
                          ),
                        ]),
                      ]),
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.oem-user-delete",
                                      modifiers: { "oem-user-delete": true },
                                    },
                                  ],
                                  attrs: { variant: "danger", block: "" },
                                },
                                [_vm._v(" Delete User Account ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c("h2", [_vm._v("An error occurred")]),
                  _vm._v(" " + _vm._s(_vm.error || "Unknown Error")),
                  _c("br"),
                  _c(
                    "b-link",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("Click here to go back")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "oem-lSub-edit",
            title: "Logger Subscription",
            size: "lg",
            "no-close-on-backdrop": "",
          },
          on: {
            ok: function ($event) {
              $event.preventDefault()
              return _vm.loggerSubSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("label", { attrs: { for: "oem-user-edit-lSub-serial" } }, [
                  _vm._v(" Serial Number "),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-input", {
                        attrs: {
                          id: "oem-user-edit-lSub-serial",
                          state: _vm.loggerSubErrors.serial,
                        },
                        model: {
                          value: _vm.loggerSubEdit.serial,
                          callback: function ($$v) {
                            _vm.$set(_vm.loggerSubEdit, "serial", $$v)
                          },
                          expression: "loggerSubEdit.serial",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { state: _vm.loggerSubErrors.serial } },
                        [_vm._v(" Serial number must be in the form PMXSXXXX ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("label", { attrs: { for: "oem-user-edit-lSub-quota" } }, [
                  _vm._v(" Quota "),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-input", {
                        attrs: {
                          id: "oem-user-edit-lSub-quota",
                          type: "number",
                          min: "1",
                          disabled: _vm.lSubQuotaInfinite,
                        },
                        model: {
                          value: _vm.loggerSubEdit.quota,
                          callback: function ($$v) {
                            _vm.$set(_vm.loggerSubEdit, "quota", $$v)
                          },
                          expression: "loggerSubEdit.quota",
                        },
                      }),
                      _c("b-form-select", {
                        staticStyle: { "max-width": "6em" },
                        attrs: {
                          options: _vm.quotaMultipliers,
                          disabled: _vm.lSubQuotaInfinite,
                          required: "",
                        },
                        model: {
                          value: _vm.loggerSubEdit.quotaMultiplier,
                          callback: function ($$v) {
                            _vm.$set(_vm.loggerSubEdit, "quotaMultiplier", $$v)
                          },
                          expression: "loggerSubEdit.quotaMultiplier",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        { attrs: { "is-text": "" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { checked: _vm.lSubQuotaInfinite },
                              on: {
                                change: function ($event) {
                                  return _vm.quotaChecked()
                                },
                              },
                            },
                            [_vm._v(" Unlimited ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("label", { attrs: { for: "oem-user-edit-lsub-start" } }, [
                  _vm._v(" Start Date "),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-input", {
                        attrs: { id: "oem-user-edit-lsub-start", type: "date" },
                        model: {
                          value: _vm.loggerSubEdit.start,
                          callback: function ($$v) {
                            _vm.$set(_vm.loggerSubEdit, "start", $$v)
                          },
                          expression: "loggerSubEdit.start",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { variant: "accent" },
                              on: {
                                click: function ($event) {
                                  _vm.loggerSubEdit.start = _vm.today
                                },
                              },
                            },
                            [_vm._v(" Today ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("label", { attrs: { for: "oem-user-edit-lsub-end" } }, [
                  _vm._v(" End Date "),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-input", {
                        attrs: {
                          id: "oem-user-edit-lsub-end",
                          type: "date",
                          min: _vm.loggerSubEdit.start,
                          disabled: _vm.loggerSubEdit.end === null,
                        },
                        model: {
                          value: _vm.loggerSubEdit.end,
                          callback: function ($$v) {
                            _vm.$set(_vm.loggerSubEdit, "end", $$v)
                          },
                          expression: "loggerSubEdit.end",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        { attrs: { "is-text": "" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                checked: _vm.loggerSubEdit.end === null,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.loggerSubEndDateChecked()
                                },
                              },
                            },
                            [_vm._v(" Infinite ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "deletion-modal",
          attrs: { id: "oem-user-delete", title: "Delete User", size: "lg" },
          on: {
            show: function ($event) {
              return _vm.checkDeletionConditions()
            },
            close: function ($event) {
              _vm.deletion.emailConfirmation = ""
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " Are you sure you want to delete this user? This action is irreversible! "
            ),
          ]),
          _c("p", [_vm._v("Prerequisites: ")]),
          _c(
            "ul",
            {
              class: _vm.deletion.hasNoLoggers ? "text-success" : "text-danger",
            },
            [
              _c(
                "li",
                [
                  _vm._v(" User is not the owner of any logger "),
                  _vm.deletion.hasNoLoggers == true
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "check" },
                      })
                    : _vm.deletion.hasNoLoggers === false
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "times" },
                      })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "primary" },
                      }),
                ],
                1
              ),
            ]
          ),
          _vm.deletion.hasNoLoggers === false
            ? _c("p", { staticClass: "text-danger" }, [
                _vm._v(" Please resolve the prerequisites before deleting "),
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Type user email address to confirm",
                      disabled: _vm.deletion.hasNoLoggers !== true,
                    },
                    model: {
                      value: _vm.deletion.emailConfirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.deletion, "emailConfirmation", $$v)
                      },
                      expression: "deletion.emailConfirmation",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "danger",
                            disabled:
                              _vm.deletion.emailConfirmation !==
                              _vm.deletion.email,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteUser()
                            },
                          },
                        },
                        [_vm._v(" DELETE USER ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }